import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import "./Menus.css";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Menus() {
  const isLogin = window.localStorage.getItem("isLogin");
  // const { data, isSuccess } = useGetMenuListQuery()
  const { data: categoryData } = useGetMenuListQuery();

  const [data, setdata] = useState(null);
  const [colectionData, setColectionData] = useState(null);


  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/category/public`, { withCredentials: true }
    );
    setdata(res.data)
    const resColection = await axios.get(
      `https://onlineparttimejobs.in/api/industry/public`, { withCredentials: true }
    );
    setColectionData(resColection.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const { t } = useTranslation()

  return (
    <>
      <div
        className="collapse navbar-collapse mean-menu"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav m-0 p-0">
          <li className="nav-item">
            <Link to="/" className="nav-link">{t('Home')}</Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/about" className="nav-link">
              About Us
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link to="/products" className="nav-link">
              {t('All Products')}
              <i className="bx bx-chevron-down"></i>
            </Link>

          </li> */}
          <li className="nav-item">
            <Link to="/about" className="nav-link">
              {t('About Us')}
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="#" className="nav-link">
              {t('Women')}
              <i className="bx bx-chevron-down"></i>
            </Link>
            <ul className='dropdown-menu dropAgro '>
              <div className="row">
                <div className="col-lg-3 width-100pr">
                  <div
                    className="menuList"
                    style={{ paddingTop: "0px" }}
                  >
                    <ul>
                      {colectionData && colectionData?.map((item) => {
                        return (
                          <li className="nav-item" key={item?._id}>
                            <Link
                              to={`product/category/${item.uid}/${item.slug}`}
                              className="nav-link sub_menu_Text"
                            >
                              <FiChevronRight />  {item?.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li className="nav-item">
            <Link to="#" className="nav-link">
              {t('Men')}
              <i className="bx bx-chevron-down"></i>
            </Link>
            <ul className='dropdown-menu dropAgro '>
              <div className="row">
                <div className="col-lg-3 width-100pr">
                  <div
                    className="menuList"
                    style={{ paddingTop: "0px" }}
                  >
                    <ul>

                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />  SM| Man'19
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="#" className="nav-link">
              {t('Magazine')}
              <i className="bx bx-chevron-down"></i>
            </Link>
            <ul className='dropdown-menu dropAgro '>
              <div className="row">
                <div className="col-lg-3 width-100pr">
                  <div
                    className="menuList"
                    style={{ paddingTop: "0px" }}
                  >
                    <ul>

                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />Magazine
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />Girls Caught Up In The Euphoria
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />One with Urmi Dagga
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </li>


          {/* {data &&
            data.map((item, i) => {
              return (
                <li className="nav-item perent" key={i}>
                  <Link
                    to={`product/category/${item.uid}/${item.slug}`}
                    className="nav-link"
                  >
                    {item.name}
                    <i className="bx bx-chevron-down"></i>
                  </Link>

                  {item?.Submenu?.length > 0 && (
                    <ul className={`dropdown-menu dropAgro ${i}`}>
                      <div className="row">
                        <div className="col-lg-3 width-100pr">
                          <div
                            className="menuList"
                            style={{ paddingTop: "0px" }}
                          >
                            <ul>
                              {item.Submenu &&
                                item.Submenu.map((item) => {
                                  return (
                                    <li className="nav-item" key={item._id}>
                                      <Link
                                        to={`product/category/${item._id}`}
                                        className="nav-link sub_menu_Text"
                                      >
                                        <FiChevronRight /> {item.name} dd
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  )}
                </li>
              );
            })} */}

          <li className="nav-item">
            <Link to="#" className="nav-link">
              {t('Categories')}
              <i className="bx bx-chevron-down"></i>
            </Link>
            <ul className='dropdown-menu dropAgro '>
              <div className="row">
                <div className="col-lg-3 width-100pr">
                  <div
                    className="menuList"
                    style={{ paddingTop: "0px" }}
                  >
                    <ul>

                      {data && data?.map((item) => {
                        
                        return (
                          <li className="nav-item" >
                            <Link
                              to={`product/category/${item.uid}/${item.slug}`}
                              className="nav-link sub_menu_Text"
                            >
                              <FiChevronRight />{item?.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li className="nav-item">
            <Link to="#" className="nav-link">
              {t('Gift Items')}
              <i className="bx bx-chevron-down"></i>
            </Link>
            <ul className='dropdown-menu dropAgro '>
              <div className="row">
                <div className="col-lg-3 width-100pr">
                  <div
                    className="menuList"
                    style={{ paddingTop: "0px" }}
                  >
                    <ul>

                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />Tranquil Set
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />Fresh Bud Set

                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link
                          to='#'
                          className="nav-link sub_menu_Text"
                        >
                          <FiChevronRight />Flower Power Set

                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-link">
              Contact Us
              <i className="bx bx-chevron-down"></i>
            </Link>
          </li>

          {isLogin === true && (
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Services
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Menus;
