import ReturnExchangePolicy from "../../components/returnExchangePolicy/ReturnExchangePolicy"


function ReturnExchangePolicyPage() {
    return (
        <>
            <ReturnExchangePolicy />
        </>
    )
}
export default ReturnExchangePolicyPage