import img from '../../../assets/shikha/logo/1_f1077b6b-445b-443a-8412-daffd2b589e9.png'
function OurFeature() {
    return (
        <>
            <section className="OurFeature">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <div className='heading-feature'>
                                <h1 className='text-center'>Our Featured On</h1>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="feature-our">
                                <img src={img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OurFeature
