import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetFeaturedProductQuery } from '../../products/productSlice'
import FeaturedProduct from './featured-product/FeaturedProduct'
import QuiekViewModal from '../../../pages/QueikViewModal/QuiekViewModal'
import { featuredDB } from '../../../rki-data/category'
import axios from 'axios'
import { base_url } from '../../../server'
import { useTranslation } from 'react-i18next'


function FeaturedProducts() {
  const [data , setData] = useState()
  const [error , seterror] = useState(false)
  const [isLoading , setisLoading] = useState(false)

  const getdata = async (page) => {
    setisLoading(true)
    try {
      // const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${page}&${12}`, { withCredentials: true })
      const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/0&26`, { withCredentials: true })
      setData(res.data)
      setisLoading(false)
    } catch (error) {
      alert('Server Error !')
      seterror(true)
      setisLoading(false)
    }
  }
  useEffect(() => {
    getdata(0)
  }, [])

  const [modelDataId, setModelDataId] = useState(null)
  const setProduct_id = (id) => {
    setModelDataId(id)
  }

  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation()
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };
  return (
    <>
      <section className="productsSection  p-30">
        <div className="container">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3 style={{ display: "flex", justifyContent: "center" }}>{t('TRENDING PRODUCTS')}</h3>
                  <p></p>
                </div>
                <div className="fisherman-btn d-none">
                  <Link to="/products" className="optional-btn">
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <FeaturedProduct data={data} getFeaturedPro={getdata} isLoading={isLoading} error={error} handleShow={handleShow} setProduct_id={setProduct_id} />
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>
    </>
  )
}

export default FeaturedProducts