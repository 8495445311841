import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";

// import payment1 from "../../../../assets/img/payment/1.svg";
// import payment2 from "../../../../assets/img/payment/2.svg";
// import payment3 from "../../../../assets/img/payment/3.svg";
// import payment4 from "../../../../assets/img/payment/4.svg";
// import payment5 from "../../../../assets/img/payment/5.svg";
// import payment6 from "../../../../assets/img/payment/6.svg";
// import payment7 from "../../../../assets/img/payment/7.svg";
import Spinner from "react-bootstrap/Spinner";

import { FiPackage, FiShoppingCart } from "react-icons/fi";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { TbTags } from "react-icons/tb";
import { RxCross1 } from "react-icons/rx";
import { BiLoaderAlt, BiSolidColor } from "react-icons/bi";

import {
  setWishCalc,
  useGetPickUpPointsQuery,
  usePickupStockMutation,
  useSetWishListMutation,
} from "../../../products/productSlice";

import "./ProductDetailContent.css";
import { QuantityCounter } from "../../../cart/QuantityCounter";
import { CustomToaster } from "../../../../common/toaster/CustomToaster";
import axios from "axios";
import OthersSellers from "./OthersSellers";
import { BsChatLeftQuoteFill, BsFillCartFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
function createMarkup(data) {
  return { __html: data };
}
function ProductDetailContent({
  changeImage,
  wish,
  data,
  isLoading,
  value,
  isAddToCartSuccess,
  isAddToCartError,
  isError,
  shortVariant,
  setCount1,
  count1,
  getAllDeta,
  t,
  setVariantsval,
  handleShow,
  handleAddCart,
  isAddCartLoading,
  BuyNowItem,
}) {
  const curr = window.localStorage.getItem("currencySym");
  let currencySymbol = curr;
  if (currencySymbol === "undefined") {
    currencySymbol = "QAR";
  }
  const { data: pickUpPointsData, isSuccess: pickupSuccess } =
    useGetPickUpPointsQuery();
  const [setWishList, { data: wishData, isSuccess, isError: wisherr }] =
    useSetWishListMutation();

  const [str, setStr] = useState(null);
  const [showTaoster, setShowToaster] = useState({
    show: false,
    message: "",
    color: "success",
  });
  const [pickupData, setPickupData] = useState();
  const [variantId, setVariantId] = useState("");
  const [storeAdd, setStoreAdd] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedAtributeValue, setSelectedAtributeValue] = useState([]);
  const handleActive = (itemId, itemIndex, item) => {
    changeImage(item);
    setCount1(itemIndex);
    window.localStorage.setItem("variationsId", item.uid);
    window.localStorage.setItem("sellerId", data.seller_id);
    setVariantId(itemId);
    setStoreAdd(null);
    shortVariant(item);
    window.localStorage.setItem("variant_id", itemId);
    window.localStorage.setItem(
      "SKU",
      data?.variations[itemIndex].prices[0].sku
    );
    setVariantsval(item);
  };
  const isLogin = window.localStorage.getItem("isLogin");
  const token = window.localStorage.getItem("token");
  const handleWishlist = (id) => {
    if (isLogin === "false") {
      setShowToaster({ show: true, message: "Login First !", color: "danger" });
      return;
    }
    const paylode = {
      userid: window.localStorage.getItem("user_id"),
      productid: id,
    };
    setWishList({ data: paylode, token: token });
  };

  const getDataPickUp = async (id) => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/pickupPoints/${id}`
    );
    setPickupData(res.data);
  };

  const handlePick = (pickup) => {
    setStr(pickup.pickupPoints.pickupPoint_name);
    window.localStorage.setItem("deliveryType", "Pickup Point Delivery");
    window.localStorage.setItem("pickUpPoint", pickup.pickupPoints._id);
    getDataPickUp(pickup.pickupPoints._id);
  };

  const dispacher = useDispatch();
  useEffect(() => {
    if (isAddToCartSuccess) {
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
    }
    if (isAddToCartError) {
      setShowToaster({
        show: true,
        message: "Something went wrong!",
        color: "danger",
      });
    }
    if (isSuccess) {
      getAllDeta();
      dispacher(setWishCalc(wishData?.wishlist?.length));
      setShowToaster({
        show: true,
        message: "Product WishList Add successfully!",
        color: "success",
      });
    }
    if (wisherr) {
      setShowToaster({
        show: true,
        message: "Product WishList Removed successfully!",
        color: "success",
      });
    }
  }, [isSuccess]);

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false });
  };

  useEffect(() => {
    window.localStorage.setItem("pickUpPoint", null);
    window.localStorage.setItem("deliveryType", "HOME DELIVERY");
    setStr(null);
  }, [pickupSuccess, data, isAddToCartSuccess, isAddToCartError, isSuccess]);

  const homeDelever = () => {
    setStr(null);
    window.localStorage.setItem("pickUpPoint", null);
    window.localStorage.setItem("deliveryType", "HOME DELIVERY");
  };
  useEffect(() => {
    window.localStorage.setItem("productCount", 1);
    window.localStorage.setItem("shippingId", "");
    console.log();
    setVariantsval(data?.variations[0]);
    window.localStorage.setItem("variant_id", data?.variations[0]?._id);
    if (data?.variation_Form) {
      let firstItem = [];
      let varientData = [];
      data.variation_Form.forEach((item) => {
        let obj = {};
        obj.title = item.title;
        obj.str = item?.data[0];
        firstItem.push(item?.data[0]);
        varientData.push(obj);
      });
      console.log("varientData", varientData);
      setArrSave(varientData);
      setSelectedAtributeValue(firstItem);
      handleActive(data?.variations[0].uid, 0, data.variations[0]);
    }
  }, [data]);

  const setStoreSeller = (value) => {
    setStoreAdd(value);
    window.localStorage.setItem("otherSeller", value?.seller_id._id);
    window.localStorage.setItem("SKU", value?.sku);
  };

  const [getData, { data: dataVal, isSuccess: loads }] =
    usePickupStockMutation();
  const [newData, setNewData] = useState();

  const getPickupsPoints = () => {
    let val = window.localStorage.getItem("variationsId");
    if (val === "null") {
      val = data?.variations[0]._id;
    }
    let obj = {
      product_id: data._id,
      variant: val,
    };
    getData({ data: obj, token: token });
  };

  useEffect(() => {
    setNewData(dataVal);
    console.log(dataVal);
  }, [dataVal]);
  const param = useParams();
  const [arrSave, setArrSave] = useState([]);

  const getOnlyString = (str) => {
    const modifiedString = str.replace(
      /[\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g,
      ""
    );
    const lowercaseString = modifiedString.toLowerCase();
    const sortedString = lowercaseString.split("").sort().join("");
    return sortedString;
  };

  const changeRequar = (str, title) => {
    let variantState = [...arrSave];
    console.log("variantState", variantState);
    if (variantState.length === 0) {
      variantState.push({ title, str });
    } else {
      let found = false;
      variantState.forEach((variant) => {
        if (variant.title === title) {
          found = true;
          variant.str = str;
        }
      });
      if (!found) {
        variantState.push({ title, str });
      }
    }
    setArrSave(variantState);
    let attributes = variantState.map((item) => item.str);
    setSelectedAtributeValue(attributes);
    data.variations.forEach((item, index) => {
      if (getOnlyString(item.weight) === getOnlyString(attributes?.join(" "))) {
        handleActive(item.uid, index, item);
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <div className="loaderIcon details_loader">
          <BiLoaderAlt />
        </div>
      ) : (
        <div className="product-details-desc ">
          <CustomToaster
            color={showTaoster.color}
            title={data?.name}
            show={showTaoster.show}
            setShow={handleToaster}
            message={showTaoster.message}
            position="bottom-end"
            delay={5000}
          />

          <div className="d-flex">
            <h6>{data?.name}</h6>
            &nbsp; &nbsp;
            {wish ? (
              <AiTwotoneHeart
                style={{ fontSize: "20px" }}
                onClick={() => {
                  handleWishlist(data?.uid);
                }}
              />
            ) : (
              <AiOutlineHeart
                style={{ fontSize: "20px" }}
                className="productWishList"
                l
                onClick={() => {
                  handleWishlist(data?.uid);
                }}
              />
            )}
          </div>

          {isError && (
            <h3 style={{ color: "red" }}>Something Went Wrong Server Error </h3>
          )}

          {data?.brand_id && (
            <h6>
              {t("Brand")} : {data.brand_id.name}{" "}
            </h6>
          )}
          {false ? (
            "----"
          ) : (
            <div className="price">
              <span className="new-price">
                {t("Price")}:{" "}
                <span
                  style={{
                    borderRadius: "5px",
                    color: "#000",
                  }}
                >
                  {
                    data?.variations[count1]?.prices[0]?.country_id?.currency_id
                      ?.symbol
                  }
                </span>{" "}
                <span></span>
                <>
                  {data ? (
                    <span style={{ fontSize: "24px" }}>
                      {data?.variations[count1]?.prices[0]?.sale_rate}
                    </span>
                  ) : (
                    // <span>{data?.variations[1]?.prices[1]?.sale_rate}</span>
                    <span>----</span>
                  )}
                  {/* {data ? (
                    <span style={{ margin: "0 10px" }} className="old-price">
                      {t('MRP')}: <span style={{ background: "green", padding: "1px 12px", borderRadius: "5px", color: "white" }}>{data?.variations[count1]?.prices[0]?.country_id?.currency_id?.symbol}</span> {data?.variations[count1]?.prices[0]?.mrp}

                    </span>
                  ) : (
                    <span style={{ margin: "0 10px" }} className="old-price">
                      {t('MRP')}: <span style={{ background: "green", padding: "1px 12px", borderRadius: "5px", color: "white" }}>{data?.variations[count1]?.prices[0]?.country_id?.currency_id?.symbol}</span> {data?.variations[count1]?.prices?.mrp}

                    </span>
                  )} */}
                  {/* {data ? (
                    <span className="save-price text-success">
                      {t('You save')}: {currencySymbol}{" "}
                      {data?.variations[0]?.prices[0]?.mrp - data?.variations[count1]?.prices[0]?.sale_rate}
                    </span>
                  ) :
                    (
                      <span className="save-price text-success">
                        {t('You save')}: {currencySymbol}{" "}
                        {data?.variations[count1]?.prices[0]?.mrp - data?.variations[count1]?.prices[0]?.sale_rate}
                      </span>
                    )
                  }           */}{" "}
                </>
              </span>
            </div>
          )}
          {/* {storeAdd ? (
            <span className="allTaxes">{storeAdd.tax_type} of All Taxes.</span>
          ) : (
            <span className="allTaxes">

              {data?.variations[0]?.prices[0]?.tax_type} of All Taxes.
            </span>
          )} */}
          {/* 
          {data?.tags?.length > 0 && (
            <div className="shareProductSec borderTop">
              <div className="titleText">
                <TbTags />
                <h6>
                  TAGS:
                  {data.tags.map((item, i) => {
                    return (
                      <span key={i} className="tags">
                        {item}
                      </span>
                    );
                  })}
                </h6>
              </div>
            </div>
          )} */}

          <div className="storagecontainer borderTop">
            <div className="storageInfo">
              <div className="productColorInfo">
                <ul className="storageNumber productColorChoose d-block">
                  {data?.variation_Form &&
                    data?.variation_Form.map((item, i) => {
                      return (
                        <li>
                          <span>{item?.title}: </span>
                          {item.data.map((val) => {
                            return (
                              <button
                                type="button"
                                className={classnames({
                                  active: selectedAtributeValue.includes(val),
                                })}
                                style={{ margin: "4px" }}
                                onClick={() => {
                                  changeRequar(val, item?.title);
                                }}
                              >
                                {val}
                              </button>
                            );
                          })}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="productCount borderTop">
              <div className="titleText">
                <FiPackage />
                <h6>{t("Add Quantity")}</h6>
              </div>
              <div className="addQuantity _p-qty">
                <div className="IncItem">
                  <QuantityCounter
                    countValue={data?.minimum_order_qty || 1}
                    val={data}
                  />
                </div>
              </div>
            </div>

            <div className="productColorInfo">
              <div className="titleText">
                <BiSolidColor />
                <h6>{t("AVAILABILITY")} :</h6>
                <span>{data?.hide_stock ? "Out of Stock" : " In Stock"}</span>
                {/* <h6>{t('color Avilable')}</h6> */}
              </div>
            </div>
          </div>

          <div className="product-details-desc">
            {data?.getaProduct?.quotation ? (
              <div className="product-add-to-cart addToCart">
                <button
                  type="button"
                  className={classnames("default-btn cart btn-row")}
                  onClick={handleShow}
                >
                  Ask Quotation
                  <BsChatLeftQuoteFill className="svg-icon" />
                </button>
              </div>
            ) : (
              <div className="product-add-to-cart addToCart">
                {isLogin === "true" ? (
                  <button
                    type="button"
                    className={classnames("default-btn cart btn-row", {
                      disabled: isAddCartLoading,
                    })}
                    onClick={() => {
                      handleAddCart(data?.product?.uid);
                    }}
                  >
                    <FiShoppingCart className="svg-icon" />
                    {t("Add to Cart")}
                    {isAddCartLoading && (
                      <Spinner animation="border" className="spinner" />
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={classnames("default-btn cart btn-row", {
                      disabled: isAddCartLoading,
                    })}
                    onClick={() => {
                      handleAddCart(data?.product?.uid);
                    }}
                  >
                    <FiShoppingCart className="svg-icon" />
                    {t("Add to Cart")}
                  </button>
                )}

                <button
                  type="Button"
                  className="default-btn buy"
                  onClick={() => BuyNowItem(data)}
                >
                  <BsFillCartFill />
                  {t("Buy it now")}
                </button>
              </div>
            )}
          </div>

          {data?.productDescription ? (
            <div className="sizeDefine">
              <h4>Description</h4>
              <div
                className="allsizeDefine"
                dangerouslySetInnerHTML={createMarkup(data?.productDescription)}
              ></div>
            </div>
          ) : (
            ""
          )}

          {data?.variations ? (
            <div className="sizeDefine">
              <div className="allsizeDefine">
                {/* <h4>Size</h4> */}
                {data?.variations[0]?.attributeList?.map((item) => {
                  return (
                    <div>
                      <h5>{item?.attributeSetMaster?.name}</h5>
                      {item?.list?.map((val) => {
                        return (
                          <>
                            <h6>
                              {val.attribute?.name} – <span>{val?.value}</span>
                            </h6>
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div>
            <h3>
              About Brand:{" "}
              <span className="span-dis">
                At STUDIO Paradise Cotton, we take immense pride in our unique
                and captivating approach to fashion. Our USP lies in the
                seamless fusion of the age-old art of block printing with
                contemporary silhouettes. Every piece in our collection is a
                work of art, meticulously handcrafted by skilled artisans who
                carry forward the legacy of block printing. Witness the passion
                and dedication that goes into each stroke and hand cut work
                resulting in distinctive patterns that tell stories of tradition
                and craftsmanship. These styles allow you to embrace effortless
                elegance in your everyday style. Feel comfortable, confident,
                and connected to your roots, all at once. With each purchase
                from our handcrafted collection, you're not just investing in
                high-quality fashion, you're also empowering artisans and
                supporting their communities.
              </span>
            </h3>
          </div>

          <div className="storagecontainer borderTop">
            <div className="storageInfo">
              {/* <div className="titleText">
                <TbTruckDelivery />
                <h6>DELIVERY MODE & SELLER</h6>
              </div> */}
              <ul
                className="deliveryModeList storageNumber"
                id="myTab"
                role="tablist"
              >
                {/* <li role="presentation">
                  <button
                    className={`nac-link ${!str && "active"}`}
                    id="homeDelivery-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#homeDelivery"
                    type="button"
                    role="tab"
                    aria-controls="homeDelivery"
                    aria-selected="true"
                    onClick={homeDelever}
                  >
                    Home Delivery
                  </button>
                </li> */}
                <button
                  type="button"
                  className={`btn btn-primary PickUpTab ${str && "active"}`}
                  data-bs-toggle="modal"
                  data-bs-target="#pickupStore"
                  onClick={getPickupsPoints}
                >
                  Pick up from store
                </button>
                {str && <div>Pick up Point :{str}</div>}

                <div
                  className="modal fade"
                  id="pickupStore"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog sellerPriceInfo">
                    <div className="modal-content">
                      <div className="sellerPriceContent">
                        <div className="sellerPriceHeader">
                          <h5>Pick up from store</h5>
                          <hr />
                          <button
                            type="button"
                            className="changeModalCancel"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <RxCross1 />
                          </button>
                        </div>
                        <div className="modal-body sellerPriceBody p-0">
                          {dataVal?.length ? (
                            <div className="pickupList">
                              {dataVal.map((item, i) => {
                                if (!item.overSelling) {
                                  return (
                                    <div
                                      className="form-check mb-2 d-flex"
                                      key={item._id}
                                    >
                                      {!item.qty <= 0 && (
                                        <>
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`flexRadioDefault`}
                                            id={item._id}
                                            value={item?.pickupPoints?.address}
                                            onClick={() => {
                                              handlePick(item);
                                            }}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            readOnly
                                          />
                                          <label
                                            style={{ marginLeft: "10px" }}
                                            className="form-check-label"
                                            htmlFor={`flexRadioDefault`}
                                            onClick={() => {
                                              handlePick(item);
                                            }}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            {
                                              item?.pickupPoints
                                                ?.pickupPoint_name
                                            }
                                            dd
                                          </label>
                                          <div style={{ marginLeft: "20px" }}>
                                            ({item.qty})
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <>
                                      {item.qty > 0 && (
                                        <div
                                          className="form-check mb-2 d-flex"
                                          key={item._id}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`flexRadioDefault`}
                                            id={item._id}
                                            value={item?.pickupPoints?.address}
                                            onClick={() => {
                                              handlePick(item);
                                            }}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            readOnly
                                          />
                                          <label
                                            style={{ marginLeft: "10px" }}
                                            className="form-check-label"
                                            htmlFor={`flexRadioDefault`}
                                            onClick={() => {
                                              handlePick(item);
                                            }}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            {
                                              item?.pickupPoints
                                                ?.pickupPoint_name
                                            }
                                          </label>
                                          {item.qty > 0 && (
                                            <div style={{ marginLeft: "20px" }}>
                                              ({item.qty})
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            <div>No Pickup Points...</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              {/* <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="homeDelivery"
                  role="tabpanel"
                  aria-labelledby="homeDelivery-tab"
                >
                  <div className="deliveryDetail">
                    <div className="deliveryVan">
                      <span className="vanCircle">
                        <img
                          src={pickupVan}
                          alt="Product"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                    <div className="deliveryDays">
                      <h6>Free Home Delivery</h6>
                      <p>in 1-2 days</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {str && (
            <div className="sellerInformation">
              <h5 className="sellerTitle">Pickup Address Detail</h5>
              <div className="sellerInfo">
                <span>You are buying from:</span>
                <p>
                  <h6>Pickup Point Name : {pickupData?.pickupPoint_name}</h6>
                </p>
                <h6>{pickupData?.address}</h6>
                <h6>Phone :{pickupData?.phone}</h6>
                <p>
                  <h6>Province : {pickupData?.province}</h6>
                </p>
              </div>
              <em className="sellerInfoProd">
                Product price may vary basis the selected seller
              </em>

              <div
                className={`modal fade`}
                id="sellerPriceModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog sellerPriceInfo">
                  <div className="modal-content ">
                    <div className="sellerPriceContent">
                      <div className="sellerPriceHeader">
                        <h5>Select a seller</h5>
                        <button
                          type="button"
                          className="changeModalCancel"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <RxCross1 />
                        </button>
                      </div>
                      <div className="sellerPriceBody">
                        <ul
                          className="deliveryModeList storageNumber"
                          id="myTab"
                          role="tablist"
                        >
                          <li role="presentation">
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              typeof="button"
                            >
                              Home Delivery
                            </button>
                          </li>
                          <li role="presentation">
                            <button
                              className="nav-link"
                              id="store-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#store"
                              type="button"
                              role="tab"
                              aria-controls="store"
                              typeof="button"
                            >
                              Store Pickup
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="homePriceInfo">
                              <div className="homePriceName">
                                <input
                                  type="radio"
                                  checked
                                  className="checkBox"
                                  readOnly
                                />
                                <span className="name">Zebrs</span>
                              </div>
                              <div className="priceSec">
                                <span className="priceInText">Price</span>
                                <span className="priceInInteger">
                                  ZK 57,754
                                </span>
                              </div>
                            </div>
                            <div className="homePriceInfo">
                              <div className="homePriceName">
                                <input
                                  type="radio"
                                  className="checkBox"
                                  readOnly
                                />
                                <span className="name">
                                  National Distributor
                                </span>
                              </div>
                              <div className="priceSec">
                                <span className="priceInText">Price</span>
                                <span className="priceInInteger">
                                  ZK 24,999
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="store"
                            role="tabpanel"
                            aria-labelledby="store-tab"
                          >
                            <div className="homePriceInfo">
                              <div className="homePriceName">
                                <input
                                  type="radio"
                                  checked
                                  className="checkBox"
                                  readOnly
                                />
                                <span className="name">
                                  vijay sales, lajpatnagar
                                </span>
                              </div>
                              <div className="priceSec">
                                <span className="priceInText">Price</span>
                                <span className="priceInInteger">
                                  ZK 37,754
                                </span>
                              </div>
                            </div>
                            <div className="homePriceInfo">
                              <div className="homePriceName">
                                <input
                                  type="radio"
                                  className="checkBox"
                                  readOnly
                                />
                                <span className="name">
                                  vijay sales, kalkaji
                                </span>
                              </div>
                              <div className="priceSec">
                                <span className="priceInText">Price</span>
                                <span className="priceInInteger">
                                  ZK 24,999
                                </span>
                              </div>
                            </div>
                            <div className="homePriceInfo">
                              <div className="homePriceName">
                                <input
                                  type="radio"
                                  className="checkBox"
                                  readOnly
                                />
                                <span className="name">
                                  anand electronics, south delhi
                                </span>
                              </div>
                              <div className="priceSec">
                                <span className="priceInText">Price</span>
                                <span className="priceInInteger">
                                  ZK 37,754
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sellerScorecard">
                          <em className="text">
                            The Sellers are listed as per your internal city and
                            scorecard
                          </em>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {storeAdd && (
            <div className="sellerInfo">
              <span>You are buying from:</span>
              <p>
                <h6>
                  Store Name : {storeAdd.seller_id.firstname}{" "}
                  {storeAdd.seller_id.lastname}
                </h6>
              </p>

              <p>
                <strong>Address Line 1</strong>
                <h6>{storeAdd.seller_id?.addressLine1}</h6>
              </p>
              <p>
                <strong>Address line 2</strong>
                <h6> {storeAdd.seller_id?.addressLine1}</h6>
              </p>
              <p>
                <strong>Seller Company Name</strong>
                <h6>{storeAdd.seller_id?.company}</h6>
              </p>

              <p>
                <strong>landmark</strong>
                <h6> {storeAdd.seller_id?.landmark}</h6>
              </p>
            </div>
          )}

          {/* <h6
            style={{
              color: "#0074c9",
              cursor: "pointer",
              textAlign: "center",
              marginTop: "7px",
            }}
            onClick={() => setModalShow(true)}
          >
            {t("View More Sellers (Click Here)")}{" "}
          </h6> */}

          {/* <div className="buy-checkbox-btn">
            <div className="trustFigure">
              <img src={trust} alt="Product" className="img-fluid" />
            </div>
          </div> */}

          <div className="custom-payment-options">
            <span>{t("Guaranteed safe checkout")}:</span>
            <div className="payment-methods">
              {/* <Link to="/">
                <img src={payment1} alt="image" />
              </Link> */}
              <Link to="/">{/* <img src={payment2} alt="image" /> */}</Link>
              <Link to="/">{/* <img src={payment3} alt="image" /> */}</Link>
              {/* <Link to="/">
                <img src={payment4} alt="image" />
              </Link> */}
              {/* <Link to="/">
                <img src={payment5} alt="image" />
              </Link> */}
              <Link to="/">{/* <img src={payment6} alt="image" /> */}</Link>
              {/* <Link to="/">
                <img src={payment7} alt="image" />
              </Link> */}
            </div>
          </div>
          <div
            className="custom_be07ed36-330c-456e-87db-e4b2a601c9cf"
            style={{ margin: "10px 0" }}
          >
            <div
              width="100%"
              style={{
                backgroundColor: "#fcfaef",
                borderRadius: 10,
                padding: "10px 20px 10px 20px",
              }}
            >
              <span color="#ca830a" style={{ color: "#2d2d2d" }}>
                {" "}
                {t("FREE DELIVERY FOR ORDERS ABOVE 200")}
              </span>
              <br />
              <span style={{ color: "#2d2d2d" }}>
                {t("10 QAR DELIVERY INSIDE DOHA")}
              </span>
              <br />
              <span style={{ color: "#2d2d2d" }} mce-data-marked={1}>
                {t("CASH OR CARD ON DELIVERY")}{" "}
              </span>
            </div>
          </div>

          {modalShow && (
            <OthersSellers
              show={modalShow}
              onHide={() => setModalShow(false)}
              setStoreSeller={setStoreSeller}
              data={data}
              count1={count1}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ProductDetailContent;
