import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";
import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import HandCraft from "./handCraft/HandCraft";
import OurFeature from "./ourFeature/OurFeature";
import Vlogs from "./Vlogs/Vlogs";
function Home() {
  const productData = useContext(productItemHome);

  const [data, setData] = useState(null)
  const getata = async () => {
    try {
      const res = await axios.get('https://onlineparttimejobs.in/api/category/filter/categ', { withCredentials: true })
      setData(res.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getata()
  }, []);
  return (
    <>
      <Banner />
      {/* <ServiceList /> */}
      <HandCraft />
      <ProductsCategory />

      {/* <Products productData={productData} /> */}

      <FeaturedProducts />
      {data && data?.slice(0, 3).map((item, i) => {
        return <CatagProduct key={i} item={item} />
      })}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <Brands />
      <BestSeller />
      {/* <Network /> */}
      {/* <AddingShow /> */}
      <Vlogs />
      <OurFeature />

      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
    </>
  );
}

export default Home;
