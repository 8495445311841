import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

function All_Categories() {
    const [data, setdata] = useState(null)

    const getData = async () => {
        const res = await axios.get(`https://onlineparttimejobs.in/api/category/public`,{ withCredentials: true })
        setdata(res.data)
    }

    useEffect(() => {
        getData()
    }, [])
    const { t, i18n } = useTranslation()
    return <div className="container">
        <div className="aiz-user-panel">
            <h4>{t('All Categories & Sub-Categories')}</h4>
            <div className="card">
                <div className="row" style={{ padding: "10px" }}>
                    {data && data.map((item, i) => {
                        return <div className="col-3" style={{ margin: "10px 0" }}>
                            <div style={{ display: "flex", alignItems: "center", margin: "15px 0" }}>
                                <img src={item?.icon?.url} style={{ width: "60px", height: "60px", objectFit: "cover" }} />
                                <Link style={{color:"black"}} to={`/product/category/${item?._id}/${item.slug}`}> <h6>{item?.name.toUpperCase()}</h6></Link>
                               
                            </div>
                            <div>
                                {item?.Submenu && item?.Submenu?.map((item)=>{
                                    return <h6 style={{fontWeight:"600",margin:"10px 0" }}><Link style={{color:"black"}} to={`/product/category/${item?._id}/${item.slug}`}>{item?.name.toUpperCase()
                                    }</Link></h6>
                                })}
                            </div>

                        </div>
                    })}


                </div>
            </div>
        </div>
    </div >
}
export default All_Categories