import Slider from "react-slick";
import img1 from '../../../assets/shikha/handCraft/Black1.png'
import img2 from '../../../assets/shikha/handCraft/Black2.jpg'
import img3 from '../../../assets/shikha/handCraft/Black3.jpg'
import img4 from '../../../assets/shikha/handCraft/Black4.jpg'
import img5 from '../../../assets/shikha/handCraft/Black5.jpg'
import img6 from '../../../assets/shikha/handCraft/Black6.jpg'
import img7 from '../../../assets/shikha/handCraft/Colo1.jpg'
import img8 from '../../../assets/shikha/handCraft/Color2.jpg'
import img9 from '../../../assets/shikha/handCraft/Color3.jpg'
import img10 from '../../../assets/shikha/handCraft/Color4.jpg'
import img11 from '../../../assets/shikha/handCraft/Color5.jpg'
import img12 from '../../../assets/shikha/handCraft/Color6.jpg'
import { Link } from "react-router-dom";

function HandCraftSlide() {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <>
            <div className="draft-slider">
                <Slider {...settings}>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img1} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img2} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img3} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img4} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">

                        <Link to="about">
                            <img src={img5} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img6} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img7} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img8} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img9} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img10} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img11} alt="" />
                        </Link>
                    </div>
                    <div className="slide-craft">
                        <Link to="about">
                            <img src={img12} alt="" />
                        </Link>
                    </div>
                </Slider>
            </div>

        </>
    )
}
export default HandCraftSlide