import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb"


function ReturnExchangePolicy() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Breadcrumb title="Returns Exchange Policy" t={t} />
            <section className="exchange-policy mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="exchNGE-POLICY">
                                <h4>Return,Refund, Shipping &amp; Cancellation
                                    Policy</h4>
                                <h5>// Exchanges &amp; Returns//</h5>
                                <p>We offer only exchange in sizes. If the ordered piece doesn&#39;t fit you, please ship us the piece
                                    back with a small note of your size requirements, and send us an email at info@shikhamalik.com
                                    Please note that shipping costs are non-refundable .</p>
                                <p>We will not entertain any exchanges later than 2 weeks. If you wish to exchange a piece, please
                                    send us a mail within 2 weeks of receiving your order.</p>
                                <p>Please read item descriptions carefully and note sizes of products. Please also know monitors are
                                    calibrated differently and colors, while may be close, may not be 100% accurate. In the event of
                                    an exchange, the buyer is responsible for return shipping fees.</p>
                                <p>Please note by purchasing from our website, you as the buyer agree to our shopping terms,
                                    conditions and policies.</p>
                                <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping
                                    costs are non-refundable.</p>
                                <p>If you are shipping an item over Rs 2500, you should consider using a track able shipping service
                                    or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.</p>
                                <h5>// Sale items //</h5>
                                <p>Only regular priced items may be exchanged, unfortunately sale items are final sale and cannot
                                    be exchanged/returned.</p>
                                <h4>Refund Policy</h4>
                                <p>In case of damaged/defective products, refunds will be initiated through the same mode as the
                                    payment within 10 working days.</p>
                                <h4>Shipping Information</h4>
                                <h5>// Domestic Shipping //</h5>
                                <p>Shipping charges vary with the size and mode of consignment.</p>
                                <h5>//Delivery Time//</h5>
                                <p>On placing your order, you will receive an email containing a summary of the order and also the
                                    estimated delivery time to your location. From the date of placing order, we would normally take
                                    7-10 working days to deliver your order. In few locations, the delivery may take longer,
                                    depending on the accessibility of your location by our logistics partners. In such cases, we will
                                    proactively reach out to you. Please check your emails and SMS regularly for such updates.</p>
                                <h4>Cancellation Policy</h4>
                                <p>Cancellation will only be accepted if your order hasn&#39;t been shipped.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ReturnExchangePolicy