import HandCraftSlide from "./HandCraftSlider"

function HandCraft() {
    return (
        <>
            <section className="handCraft">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="gallery-main-heading text-center">
                                <h2 className="spacingzero1 text-padding">Handcrafted</h2>
                                <span className="spacingzero2">by Master Craftspeople</span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="icon-text-gallery">
                                <div className="iconview icon1"><div className="disktopfullImage">
                                    <img src="//thejodilife.com/cdn/shop/files/slow_fashion_leaf_3ac8101c-1860-4940-a407-911dd68d5ffe.png?v=1671367377" alt loading="lazy" width={143} height={229} />
                                </div>
                                    <div className="mobileImage">
                                        <img src="//thejodilife.com/cdn/shop/files/slow_fashion_leaf_3ac8101c-1860-4940-a407-911dd68d5ffe.png?v=1671367377" alt loading="lazy" width={143} height={229} />
                                    </div>
                                    <p>Slow fashion.</p>
                                </div>
                                <div className="iconview icon2"><div className="disktopfullImage">
                                    <img src="//thejodilife.com/cdn/shop/files/hamsa_d1b4b758-f841-45ef-b49a-fcd74476bd55.png?v=1671367418" alt loading="lazy" width={200} height={230} />
                                </div>
                                    <div className="mobileImage">
                                        <img src="//thejodilife.com/cdn/shop/files/hamsa_d1b4b758-f841-45ef-b49a-fcd74476bd55.png?v=1671367418" alt loading="lazy" width={200} height={230} />
                                    </div>
                                    <p>Ethical.</p>
                                </div>
                                <div className="iconview icon3"><div className="disktopfullImage">
                                    <img src="//thejodilife.com/cdn/shop/files/handcrafted_lotus_1.png?v=1671367461" alt loading="lazy" width={199} height={187} />
                                </div>
                                    <div className="mobileImage">
                                        <img src="//thejodilife.com/cdn/shop/files/handcrafted_lotus_1.png?v=1671367461" alt loading="lazy" width={199} height={187} />
                                    </div>
                                    <p>Sustainable.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <HandCraftSlide />
            </section>
        </>
    )
}
export default HandCraft