import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";

import "./Header.css";
import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Header({changeLang}) {
  const [isSticky, setIsSticky] = useState(false);

  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  window.addEventListener("scroll", fixedHeader);

  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  });

  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/adminFrontMessage/id`,{
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }
    );
    setState({
      front_top_message: res.data.front_top_message,
      icon: res.data.icon,
    });
  };

  useEffect(() => {
    // getData();
  }, []);

  return (
    <>
      {/* <button type="button" onClick={()=>{changeLang('en')}}>EEE</button>
                    <button type="button" onClick={()=>{changeLang('de')}}>ARR</button> */}
      <header className={`${isSticky ? "sticky active" : "sticky"}`}>
        <TopHeader state={state} changeLange={changeLang}/>
        <SearchBox val={state} />
        <section className="navbarArea">
          <div className="abaris-nav">
            <div className="container">
              <div className="menuCard">
                <div className="allCategoryDrop">
                  <span>categories</span>
                  <i className="ri-menu-line"></i>

                  <div className="categoryList">
                    <ul>
                      <li><Link to="/products">Baby bottles</Link></li>
                      <li><Link to="/products">Breastfeeding Accessories</Link></li>
                      <li><Link to="/products">Bibs & Cloths</Link></li>
                      <li><Link to="/products">Teethers & Pacifiers</Link></li>
                      <li><Link to="/products">Feeding Essentials</Link></li>
                    </ul>
                  </div>
                </div>
                <nav className="navbar navbarItem navbar-expand-md navbar-light">
                  <Menus />
                </nav>
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  );
}

export default Header;
