


import React from "react";

import a1 from '../../../assets/shikha/about/IMG_0283 (3).PNG'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">
          <div className="row aboutSection-2">
            <div className="col-lg-12">

              <div className="container-2-2">
                <div className="img-story">
                  <img src={a1} alt="" />
                </div>
                <h3 className="subtitle text-center">{t('Clothes handcrafted with love.')}</h3>
                <p className="">
                  {/* {data?.about_company} */}
                  Experience the creative journey at STUDIO SM! We begin by selecting a captivating theme for our latest collection, sparking inspiration for mood-boards and storyboards. Once it is done, we dive right into sketches and prints, crafting unique designs that truly stand out.
                </p>
                <p>When we are done with mood boards, sketches and prints. We collaborate with skilled artisans to bring our visions to life, carving intricate blocks that add depth and texture to our creations.</p>
                <p>It’s always a special feeling to see your ideas come to life. When the block is ready ,we move to the next stage of developing swatches to check the quality of the block, ensuring only the best make the cut.</p>
                <p>Then comes the final stage where we experiment with various color combinations, creating samples that capture the essence of our collection. After countless trials, we handpick the ones that truly embody our vision.

                </p>
                <p>Every step of the process is a labor of love. We pour our time, energy, and patience into each collection, knowing that the end result will bring joy and excitement to all. Join us as we unveil something new and remarkable, making every moment worthwhile. Welcome to STUDIO SM, where creativity knows no bounds!</p>
                {/* <p>
                  {t('about-1')}
                </p> */}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default AboutStory;
