import { useListSellersQuery } from "../../components/products/productSlice"
// import img from "../../assets/img/banner/etg.png"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
function AllSellerList() {
    const { isLoading } = useListSellersQuery()

    const [data, setData] = useState(null)

    const getData = async () => {
        const res = await axios.get(`https://onlineparttimejobs.in/api/sellerList/public`)
        setData(res.data)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <div>
        <nav aria-label="breadcrumb" className="breadcrumbSec">
            <div className="container">
                <ol className="breadcrumb ">
                    <li className="breadcrumb-item">
                        <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">All Sellers</li>
                </ol>
            </div>
        </nav>
        {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>}

        <section className="brandsSec bg-white">
            <div className="container">
                <div className="brandsItem fullBrandsItem">
                    <div className="brandsHeader">
                        <div className="fisherman-content mb-4"><span>our Sellers</span><h3>top Seller</h3></div>
                    </div>
                    <ul>
                        {data && data.map((item) => {
                            return <li key={item._id}>
                                <Link to={`/seller/seller-home/${item._id}`}>
                                    <img src={''} alt="Brand" className="img-fluid" />
                                </Link>
                                <div>Name : {item?.firstname + " " + item?.lastname}</div>
                            </li>

                        })}

                    </ul>
                </div>
            </div>
        </section>


    </div>
}
export default AllSellerList