import React from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from '../../../assets/shikha/bestSeller/5.jpg'
import img2 from '../../../assets/shikha/bestSeller/6.jpg'
import img3 from '../../../assets/shikha/bestSeller/8.jpg'
import img4 from '../../../assets/shikha/bestSeller/10.jpg'
import { useTranslation } from "react-i18next";
import Slider from "react-slick";


import imgsl1 from "../../../assets/shikha/bestSeller/img-1.JPG";
import imgsl2 from "../../../assets/shikha/bestSeller/img-2.JPG";
import imgsl3 from "../../../assets/shikha/bestSeller/img-3.JPG";
import imgsl4 from "../../../assets/shikha/bestSeller/img-4.JPG";
import imgsl5 from "../../../assets/shikha/bestSeller/img-5.JPG";
import imgsl6 from "../../../assets/shikha/bestSeller/img-6.JPEG";
import imgsl7 from "../../../assets/shikha/bestSeller/img-7.jpg";
import imgsl8 from "../../../assets/shikha/bestSeller/img-8.JPG";
import imgsl9 from "../../../assets/shikha/bestSeller/img-9.JPEG";
const mockSlode = [
  { id: '1', img: imgsl1 },
  { id: '1', img: imgsl2 },
  { id: '1', img: imgsl3 },
  { id: '1', img: imgsl4 },
  { id: '1', img: imgsl5 },
  { id: '1', img: imgsl6 },
  { id: '1', img: imgsl7 },
  { id: '1', img: imgsl8 },
  { id: '1', img: imgsl9 },

]

function BestSeller() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeRoute = (route) => {
    if (route == "1") {
      navigate("/product/category/31/Baby-bottles");
    }
    if (route == "2") {
      navigate("/product/category/39/Dishes-and-Foods");
    }
    if (route == "3") {
      navigate("/product/category/32/Bibs-and-Pacifiers");
    }
    if (route == "4") {
      navigate(
        "/product/50/1pc%20Baby%20Bottle%20Drying%20Rack%20With%20Tray,%20High%20Capacity%20Bottle%20Dryer%20Holder%20For%20Baby%20Bottle"
      );
    }
  };
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <section className="bestSeller sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>{t("OUR TOP BEST SELLER")}</h3>
                <p></p>
              </div>
            </div>
            <div className="col-lg-6" onClick={() => changeRoute("4")}>
              <div className="sellerProduct">
                <div className="" height={440}>
                  <Slider {...settings}>
                    {mockSlode && mockSlode.map((item) => {
                      return <div key={item?.id}>
                        <img
                          //  height={100} 
                          style={{ height: "888px", width: "100%" }}
                          src={item.img}
                          alt="Product"
                          className="mainimgbuy"
                        />
                      </div>
                    })}





                  </Slider>
                  <div className="sellerProductText d-none">
                    <h2>our top best seller</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6" onClick={() => changeRoute('1')}>
              <div className="asideSeller row">
                <div className="col-lg-6">
                  <div className="asideSellerCard">
                    <div className="sellerProductCard">
                      <img src={img1} alt="Product" className="img-fluid" />
                      {/* <div className="sellerProductContent ">
                        <h2>Feedings</h2>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" onClick={() => changeRoute('2')}>
                  <div className="asideSellerCard">
                    <div className="sellerProductCard">
                      <img src={img2} alt="Product" className="img-fluid" />
                      {/* <div className="sellerProductContent ">
                        <h2 className="text-white">Fun Time</h2>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" onClick={() => changeRoute('3')}>
                  <div className="asideSellerCard">
                    <div className="sellerProductCard ">
                      <img src={img3} alt="Product" className="img-fluid" />
                      {/* <div className="sellerProductContent full ">
                        <h2>Bibs <span> & Pacifiers</span></h2>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" onClick={() => changeRoute('3')}>
                  <div className="asideSellerCard">
                    <div className="sellerProductCard ">
                      <img src={img4} alt="Product" className="img-fluid" />
                      {/* <div className="sellerProductContent full ">
                        <h2>Bibs <span> & Pacifiers</span></h2>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BestSeller;
