import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import About from '../../components/about/About'

function AboutPage({ setShow }) {

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <About />

    </>
  )
}

export default AboutPage