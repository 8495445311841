
import v1 from '../../../assets/shikha/Vlogs/15sec-4.mp4'
import v2 from '../../../assets/shikha/Vlogs/b23c53fa-7f0b-4d45-87a7-796535f95d60.mp4'
import v3 from '../../../assets/shikha/Vlogs/d987044a-4ca4-4eda-892e-2af29b448084.mp4'
function Vlogs() {
    return (
        <>
            <section className="Vlogs">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1 className='text-center'>Vlogs</h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="vlog-video">

                                <video width={415} controls autoPlay muted>
                                    <source src={v1} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag. */}
                                </video>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="vlog-video">

                                <video width={415} controls autoPlay muted>
                                    <source src={v2} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag. */}
                                </video>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="vlog-video">

                                <video width={415} controls autoPlay muted>
                                    <source src={v3} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag. */}
                                </video>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Vlogs